import React from "react";
import { Container } from "../components/Common";
import { Layout } from "../components/Layout";
import styled from "styled-components";
import { BigTitle, TitleContainer } from "../components/TextComponents";
import { StaticImage } from "gatsby-plugin-image";
import { breakpoints } from "../styledResponsive";
import { Link } from "gatsby";
import { theme } from "../theme";
import { BannerButton, ContentContainer } from "../components/Banner";
import { Break } from ".";

const BannerContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  min-height: 250px;
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px;
  ${breakpoints("margin-bottom", ["32px", "32px", "120px", "120px"])};
  ${breakpoints("margin-top", ["32px", "32px", "120px", "120px"])};
`;

const Strip = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${breakpoints("flex-direction", ["column", "column", "row", "row"])};
`;

const StripSection = styled.div`
  display: flex;
  flex: 1;
  /* padding: 80px; */
`;

const StripTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 32px;
  padding-left: 32px;
`;

const StripTextContainerReversed = styled(StripTextContainer)`
  margin-left: 0;
  margin-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StripTextTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 32px;
  font-weight: 400;
  letter-spacing: 1px;
`;

const StripText = styled.p`
  font-size: 18px;
  margin-bottom: 32px;
`;

const StripImageContainer = styled.div``;

const ImgContainer = styled.div`
  width: 100%;
  ${breakpoints("width", ["100%", "100%", "48%", "48%"])};
`;

const Title = styled(BigTitle)`
  font-size: 14px;
  margin-bottom: 0;
  ${breakpoints("font-size", ["14px", "14px", "32px", "32px"])};
`;

const BoxContact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  background-color: #eee7de;
  width: "100%";
  ${breakpoints("width", ["100%", "100%", "500px", "500px"])};
  margin: auto;
`;

const BannerImageMobile = styled(StaticImage)`
  width: 100%;
  height: 100%;
  ${breakpoints("display", ["block", "block", "none", "none"])};
`;

const BannerImageDesktop = styled(StaticImage)`
  width: 100%;
  height: 100%;
  ${breakpoints("display", ["none", "none", "block", "block"])};
`;

const AboutPage = () => {
  return (
    <Layout>
      <>
        <BannerContainer style={{ marginTop: 0 }}>
          <StaticImage src="../images/banners/banner-sobre-1.jpg" alt="Sobre" layout="fixed" quality={100} />
          <ContentContainer
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Title style={{ color: "#fff", width: "100%" }}>
              Cultivado no melhor solo da américa latina
              <br /> Desenvolvido através do melhor processo
              <br /> Na embalagem ideal para maior durabilidade
            </Title>
          </ContentContainer>
        </BannerContainer>
        <Container>
          <Strip
            style={{
              backgroundColor: theme.colors.alternative,
            }}
          >
            <StripSection>
              <StripTextContainer>
                <StripText>
                  Apaixonados por experiências gastronômicas, visitamos os
                  lugares com os azeites mais famosos para entender todo o
                  processo produtivo.
                  <br />
                  <br />
                  Passamos por Itália, Espanha, Grécia, Portugal...
                  <br />
                  <br />
                  Entendemos tudo sobre os melhores processos!
                  <br />
                  <br />
                  Estudamos sobre solos e variações climáticas e descobrimos que
                  a região da Campanha Gaúcha tem o lugar ideal no Brasil para o
                  cultivo de oliveiras.
                </StripText>
              </StripTextContainer>
            </StripSection>
            <StripSection>
              <StripImageContainer>
                <StaticImage
                  src="../images/sobre-11.jpg"
                  alt="banner-sobre"
                  style={{ width: "100%", minHeight: 300 }}
                />
              </StripImageContainer>
            </StripSection>
          </Strip>
        </Container>
        <BannerContainer style={{ marginBottom: 120, marginTop: 120 }}>
          <ContentContainer
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Title style={{ color: "#fff" }}>
              Assim nasceu a Estância do Forte.
              <Break />
              Desde o primeiro passo, focada na
              <Break />
              qualidade dos produtos, da escolha do
              <Break />
              solo, até o produto final na sua mesa.
            </Title>
            <BannerButton to="/#produtos">Conheça nossos produtos</BannerButton>
          </ContentContainer>
          <StaticImage src="../images/sobre-12.jpg" alt="banner-sobre" />
        </BannerContainer>
        <Container>
          <Strip style={{ backgroundColor: theme.colors.alternative }}>
            <StripSection>
              <StripImageContainer>
                <StaticImage
                  src="../images/sobre-13.jpg"
                  alt="banner-sobre"
                  style={{ width: "100%" }}
                />
              </StripImageContainer>
            </StripSection>
            <StripSection>
              <StripTextContainerReversed>
                <StripText style={{paddingTop: 32}}>
                  A Estância do Forte também dedica boa parte de sua área para a
                  produção de nozes pecan de extrema qualidade. São mais de 100
                  hectares plantados com mudas novas, cultivadas 100% em solo
                  gaúcho, gerando uma produção voltada tanto para mercado
                  interno quanto exportação.
                </StripText>
              </StripTextContainerReversed>
            </StripSection>
          </Strip>
        </Container>
        <BannerContainer style={{ marginBottom: 240 }}>
          <ContentContainer>
            <BoxContact>
              <Title style={{ marginBottom: 0 }}>
                Deseja vender nossos produtos no seu espaço?
              </Title>
              <BannerButton
                to="http://bit.ly/3tOkMqO "target="_blank"
                style={{ backgroundColor: "#62502F", color: "#fff" }}
              >
                Fale conosco
              </BannerButton>
            </BoxContact>
          </ContentContainer>
          <StaticImage src="../images/sobre-14.jpg" alt="banner-sobre" />
        </BannerContainer>
      </>
    </Layout>
  );
};

export default AboutPage;
